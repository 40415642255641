<template>
  <div>
    <transition>
      <!-- Don't use v-if here because it would break mobile navigation -->
      <div class="navbar" :class="{ 'hide-navbar': !$store.state.showNavbar}" v-click-outside.stop="handleClickOutside">
        <div class="logo-container">
          <icon-logo-full width="108px" height="30px" icon-fill="#347be5" text-fill="#fff" />
          <div class="close-menu-container"  v-if="$store.state.userIsMobile">
            <swift-close-round @click.native="hideNavbar()" fill="#ffffff" height="13px" width="13px" />
          </div>
        </div>

        <div class="title">
          Projects
        </div>
        <div
          class="project"
          v-for="project in $store.state.projects.projects"
          :key="project.id"
        >
          <router-link @click.native="setTicketsAreLoaded(false)" :to="{ name: 'tickets', params: { projectId: project.id.toString() }, query: { sortBy: 'updatedAt_desc'}}" class="menu-link">
            <icon-angle-down  class="menu-link-icon" v-if="$route.params.projectId === project.id.toString()" width="14px" height="14px" fill="#347be5"/>
            <icon-angle-right class="menu-link-icon" v-else width="14px" height="14px" fill="#347be5"/>
            <div class="menu-text">{{ project.name }}</div>
          </router-link>
          <div class="submenu" v-if="$route.params.projectId === project.id.toString()">
            <router-link :to="{ name: 'project-settings', params: { projectId: project.id.toString() }}" class="submenu-link">
              <icon-gear width="14px" height="12px" fill="#347be5"/>
              <div class="submenu-text">Settings</div>
            </router-link>
            <router-link :to="{ name: 'widgets', params: { projectId: project.id.toString() }}" class="submenu-link">
              <icon-widget width="14px" height="11px" fill="#347be5"/>
              <div class="submenu-text">Widgets</div>
            </router-link>
          </div>
        </div>
        <div class="add-project-positioner">
          <div
            class="add-project"
            v-if="!showCreateProject"
            @click.stop="showCreateProject = true"
            type="button"
          >
            + Add project
          </div>
          <project-create v-if="showCreateProject" @close="showCreateProject = false" />
        </div>

        <div class="title settings">
          Settings
        </div>
        <router-link class="menu-link" :to="{ name: 'team' }" >
          <icon-team width="14px" height="12px" fill="#347be5"/>
          <div class="menu-text">Team</div>
        </router-link>
        <router-link v-if="$store.state.user.user.role === 'super-admin'" class="menu-link" :to="{ name: 'admin' }" >
          <icon-team width="14px" height="12px" fill="#347be5"/>
          <div class="menu-text">Admin</div>
        </router-link>

        <div class="profile">
          <swift-user-avatar
            :user="$store.state.user.user"
            :size="30" />
          <div class="profile-right">
            <div class="profile-name">
              {{ $store.state.user.user.name }}
            </div>
            <div class="profile-logut" @click="logout()">
              Log out
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import IconLogoFull from './icons/IconLogoFull.vue'
import IconAngleRight from './icons/IconAngleRight.vue'
import IconAngleDown from './icons/IconAngleDown.vue'
import IconTeam from './icons/IconTeam.vue'
import IconGear from './icons/IconGear.vue'
import IconWidget from './icons/IconWidget.vue'
import SwiftUserAvatar from './SwiftUserAvatar.vue'
import SwiftCloseRound from './SwiftCloseRound.vue'
import ProjectCreate from './ProjectCreate.vue'

export default {
  components: {
    IconLogoFull,
    IconAngleRight,
    IconAngleDown,
    IconWidget,
    IconTeam,
    IconGear,
    SwiftUserAvatar,
    SwiftCloseRound,
    ProjectCreate
  },
  data () {
    return {
      showCreateProject: false
    }
  },
  async created () {
    this.toggleNavbar()
  },
  computed: {
    userIsMobile () {
      return this.$store.state.userIsMobile
    }
  },
  watch: {
    userIsMobile: function () {
      this.toggleNavbar()
    }
  },
  methods: {
    handleClickOutside () {
      if (this.userIsMobile && this.$store.state.showNavbar) {
        this.$store.commit('hideNavbar')
      }
    },
    hideNavbar () {
      this.$store.commit('hideNavbar')
    },
    logout () {
      this.$store.dispatch('logout')
    },
    createProject (projectData) {
      this.$store.dispatch('createProject', projectData)
    },
    toggleNavbar () {
      if (this.userIsMobile) {
        this.$store.commit('hideNavbar')
      } else {
        this.$store.commit('showNavbar')
      }
    },
    setTicketsAreLoaded (boolean) {
      this.$store.commit('setTicketsAreLoaded', boolean)
    }
  }
}
</script>

<style scoped>
.navbar {
  color: #FFF;
  background-color: #282f39;
  box-shadow: 0px 0px 15px hsla(216, 18%, 19%, 0.2);
  width: 180px;
  position: fixed;
  overflow: hidden; /* hide scrollbar only show on hover */
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  transition-duration: 0.3s;
  scrollbar-width: thin; /* firefox */
  scrollbar-color: #546278 #282f39; /* firefox */
}

.navbar:hover {
  overflow-y: auto;
  overflow-x: hidden;
}

/* WebKit Browsers */
.navbar::-webkit-scrollbar {
      width: 10px;
    }

.navbar::-webkit-scrollbar-track {
      background: #282f39;
    }

.navbar::-webkit-scrollbar-thumb {
      background: #546278;
      border-radius: 10px;
    }

.navbar::-webkit-scrollbar-thumb:hover {
      background: #546278;
    }

.hide-navbar {
  width: 0px;
  transition-duration: 0.3s;
}

.logo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 25px;
  margin-top: 35px;
  margin-bottom: 35px;
}
@media (max-width: 1025px) {
  .logo-container {
    padding-left: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.close-menu-container {
  display: flex;
  align-items: center;
  padding-right: 5px;
}

.title {
  color: #9ba8bb;
  font-weight: 600;
  font-size: 14px;
  padding-left: 25px;
  margin-bottom: 5px;
}

.project {
  font-weight: 600;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  user-select: none;
}

.menu-link {
  display: flex;
  padding-left: 25px;
  line-height: 2.5;
  display: flex;
  align-items: center;
}

.menu-link:hover{
  background-color: hsl(216, 18%, 24%);
  transition-duration: 0.1s;
}

.menu-link-icon {
  flex-shrink: 0;
}

.menu-text {
  padding-left: 4px;
  white-space: nowrap;
  overflow: hidden;
}

.submenu {
  font-weight: 400;
  font-size: 14px;
}

.submenu-link {
  padding-left: 40px;
  line-height: 2.5;
  display: flex;
  align-items: center;
}
.submenu-link:hover{
  background-color: hsl(216, 18%, 24%);
  transition-duration: 0.1s;
}

.submenu-text {
  padding-left: 4px;
}

/* this is a native vue-router class that applies on the current active route link */
.router-link-active {
  background-color: hsl(216, 18%, 27%);
  transition-duration: 0.1s;
}

.add-project-positioner {
  padding-top: 15px;
  margin-left: 20px;
  height: 60px;
}

.add-project {
  font-size: 12px;
  font-weight: 600;
  color: #9ba8bb;
  cursor: pointer;
}

.add-project:hover {
  color: #347be5;
  transition-duration: 0.1s;
}

.settings {
  margin-top: 20px;
}

.profile {
  margin-top: 50px;
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.profile-right {
  margin-left: 7px;
}
.profile-name {
  font-weight: 600;
  max-width: 110px;
  overflow: hidden;
}
.profile-logut {
  font-size: 12px;
  font-weight: 600;
  color: #9ba8bb;
  cursor: pointer;
}
.profile-logut:hover {
  color: #347be5;
  transition-duration: 0.1s;
}

</style>
