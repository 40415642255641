<template>
  <div class="global-panel widget-item" v-if="widget">
    <div class="back" @click="routeToWidgets()">
      back to list
    </div>
    <h1>
      {{ widget.name}}
    </h1>
    <div class="script">
      <div class="global-label">
        Copy and paste this script into the webpages where you want to collect feedback
      </div>
      <textarea class="global-input script-textarea"
                v-model="embedScript">
        </textarea>
    </div>
    <div class="button-settings">
      <h2>Feedback Button Settings</h2>
      <div class="button-setting">
        <div class="global-label">
          Button color
        </div>
        <input type="color" class="global-input" v-model="widget.buttonBackgroundColor">
      </div>
      <div class="button-setting">
        <div class="global-label">
          Button text color
        </div>
        <input type="color" class="global-input" v-model="widget.buttonTextColor">
      </div>
      <div class="button-setting">
        <div class="global-label">
          Button text
        </div>
        <input class="global-input" v-model="widget.buttonText">
      </div>
      <div class="button-setting">
        <div class="global-label">
          Position on page
        </div>
        <swift-select
          :initial-option="widget.buttonPosition"
          :options="buttonPositions"
          @selected="setButtonPosition($event)" />
      </div>
    </div>
    <div class="menu-settings">
      <h2>Widget Menu Settings</h2>
      <div class="menu-setting">
        <div class="global-label">
          Show description textarea
        </div>
        <input type="checkbox" v-model="widget.showDescription">
      </div>
      <div class="menu-setting">
        <div class="global-label">
          Make description mandatory
        </div>
        <input type="checkbox" v-model="widget.descriptionIsMandatory">
      </div>
      <div class="menu-setting">
        <div class="global-label">
          Show email input
        </div>
        <input type="checkbox" v-model="widget.showEmail">
      </div>
      <div class="menu-setting">
        <div class="global-label">
          Make email mandatory
        </div>
        <input type="checkbox" v-model="widget.emailIsMandatory">
      </div>
    </div>
    <div class="general-settings">
      <h2>General Settings</h2>
      <div class="general-setting">
        <div class="global-label">
          Widget name
        </div>
        <input class="global-input" v-model="widget.name">
      </div>
      <div class="general-setting">
        <div class="global-label">
          Activate widget
        </div>
        <input type="checkbox" v-model="widget.active">
      </div>
      <div class="general-setting"  v-if="$store.state.status.statuses.length > 0">
        <div class="global-label">
          Assign incoming feedback to this status
        </div>
        <swift-select-status
          :initial-status="getStatusById(widget.defaultStatusId)"
          @selectedStatus="setDefaultStatus($event)" />
      </div>
    </div>
    <div class="save-button-container">
      <swift-button
        color="blue"
        :is-loading="saveButtonIsLoading"
        @click.native="updateWidget()">Save changes</swift-button>
    </div>
  </div>
</template>

<script>
import WidgetsApi from '../api/WidgetsApi.js'
import SwiftButton from './SwiftButton.vue'
import SwiftSelectStatus from './SwiftSelectStatus.vue'
import SwiftSelect from './SwiftSelect.vue'

export default {
  components: {
    SwiftButton,
    SwiftSelectStatus,
    SwiftSelect
  },
  data () {
    return {
      widget: null,
      saveButtonIsLoading: false,
      buttonPositions: [
        'bottom-right',
        'bottom-left',
        'right-middle',
        'left-middle'
      ]
    }
  },
  async created () {
    const response = await WidgetsApi.getWidgetById(this.$route.params.widgetId)
    this.widget = response.data[0]
    await this.$store.dispatch('getStatusesOfProject', this.$store.state.projects.currentProjectId)
  },
  computed: {
    embedScript () {
      // add uuid to script strings
      /* eslint no-useless-escape: "off" */
      const script =
`<script>
(function(k, l, w, d) {     
    w.swiftbug =  w.swiftbug || []
    w.swiftbug.push({widgetKey: k})                      
    var s = d.createElement('script');    
    s.id = k;s.async = 1;s.src = l;
    var parentNode = d.head || d.body;parentNode.appendChild(s);
})('${this.widget.key}', '${process.env.VUE_APP_WIDGET_URL}', window, document)
<\/script>`

      return script
    },
    getStatusById () {
      return this.$store.getters.getStatusById
    }
  },
  methods: {
    async updateWidget () {
      this.saveButtonIsLoading = true
      await this.$store.dispatch('updateWidget', this.widget)
      this.saveButtonIsLoading = false
    },
    routeToWidgets () {
      this.$router.push({ name: 'widgets' })
    },
    setDefaultStatus (status) {
      if (!status) {
        return
      }

      this.widget.defaultStatusId = status.id
    },
    setButtonPosition (buttonPosition) {
      this.widget.buttonPosition = buttonPosition
    }
  }
}
</script>

<style scoped>
.back {
  color: #1764d9;
  cursor: pointer;
}
.script {
  margin-top: 20px;
}

.script-textarea {
  width: 100%;
  height: 200px;
  font-family: monospace;
}

.button-settings {
  margin-top: 40px;
}
.button-setting {
  margin-top: 20px;
}

.menu-settings {
  margin-top: 40px;
}
.menu-setting {
  margin-top: 20px;
}

.general-settings {
  margin-top: 40px;
}
.general-setting {
  margin-top: 20px;
}
.save-button-container {
  margin-top: 40px;
  margin-bottom: 40px;
}

</style>
