import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store/store'
import InvitePage from './components/InvitePage.vue'
import TicketList from './components/TicketList.vue'
import TeamPage from './components/TeamPage.vue'
import AdminPage from './components/AdminPage.vue'
import ProjectSettings from './components/ProjectSettings.vue'
import WidgetList from './components/WidgetList.vue'
import WidgetPage from './components/WidgetPage.vue'
import WidgetCreate from './components/WidgetCreate.vue'
import LoginPage from './components/LoginPage.vue'
import MainLayout from './components/MainLayout.vue'
import RegisterPage from './components/RegisterPage.vue'
import BrowserExtension from './components/BrowserExtension.vue'
import ForgotPasswordPage from './components/ForgotPasswordPage.vue'
import ResetPasswordPage from './components/ResetPasswordPage.vue'

// checks if user is logged in on the frontend (this is just for ux secure checking is in backend)
const requireAuth = function () {
  return (to, from, next) => {
    if (store.state.authentication.isUserLoggedIn) {
      next()
    } else {
      // redirect to login if not logged in
      next('/login')
    }
  }
}

// route matching priority is determined by the order of route definition

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/register',
      name: 'register',
      component: RegisterPage
    },
    {
      path: '/invite/:inviteToken',
      name: 'invite',
      component: InvitePage
    },
    {
      path: '/login',
      name: 'login',
      component: LoginPage
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPasswordPage
    },
    {
      path: '/reset-password/:passwordResetToken',
      name: 'reset-password',
      component: ResetPasswordPage
    },
    {
      path: '/browser-extension',
      name: 'browser-extension',
      component: BrowserExtension
    },
    {
      path: '/:organizationName',
      name: 'organization',
      component: MainLayout,
      beforeEnter: requireAuth(),
      children: [{
        path: '/:organizationName/:projectId/tickets',
        name: 'tickets',
        component: TicketList,
        beforeEnter: requireAuth(),

        children: [{
          path: '/:organizationName/:projectId/tickets/create-ticket',
          name: 'create-ticket',
          component: TicketList,
          beforeEnter: requireAuth()
        },
        {
        // sends all indivdual ticket requests to the list which than figures out which ticket to open via the params.ticketId only if the ticketid is all numbers
          path: '/:organizationName/:projectId/tickets/:ticketId(\\d+)',
          name: 'ticket-modal',
          component: TicketList,
          beforeEnter: requireAuth()
        }
        ]
      },
      {
        path: '/:organizationName/:projectId/settings',
        name: 'project-settings',
        component: ProjectSettings,
        beforeEnter: requireAuth()
      },
      {
        path: '/:organizationName/:projectId/widgets',
        name: 'widgets',
        component: WidgetList,
        beforeEnter: requireAuth()
      },
      {
        path: '/:organizationName/:projectId/widgets/create',
        name: 'create-widget',
        component: WidgetCreate,
        beforeEnter: requireAuth()
      },
      {
        path: '/:organizationName/:projectId/widgets/:widgetId',
        name: 'widget',
        component: WidgetPage,
        beforeEnter: requireAuth()
      },
      {
        path: '/:organizationName/team',
        name: 'team',
        component: TeamPage,
        beforeEnter: requireAuth()
      },
      {
        path: '/:organizationName/admin',
        name: 'admin',
        component: AdminPage,
        beforeEnter: requireAuth()
      }
      ] },
    {
    // catch all unkown routes
      path: '*',
      redirect: { name: 'login' }
    }]
})

router.afterEach((to) => {
  if (to.path !== '/login' && to.path !== '') {
    store.commit('setEntryPath', to.fullPath)
  }
})

export default router
